import resumeen from './assets/ev_en.pdf';
import resumefr from './assets/ev_fr.pdf';
import photo from './assets/pp.png';

// function onResumeClick(lg) {
//   if (lg === 'fr') window.open(resumefr);
//   else window.open(resumeen);
// }
function About() {
  return (
    <div className='About'>
      <img alt='Emma Vodable' src={photo} />
      <div className='text'>
        <div className='content'>
          <p>
            Je suis actuellement <b>Gameplay programmeuse Junior</b> à Ubisoft Bordeaux sur <b>Assassin's Creed Mirage</b>.{' '}
            J'ai une affection pour la narration, l'UI et les 3C.
          </p>
          <p>
            Avide de nouvelles connaissances et opportunités, n'hésitez pas à me contacter !
          </p>
          <hr />
          <p>
            @ : <a href='mailto:vodableemma@gmail.com'>vodableemma@gmail.com</a>
          </p>
          <p>
            Resume/CV{' : '}
            <a href={resumeen} download='ResumeEmmaVodable'>
              en
            </a>
            {' - '}
            <a href={resumefr} download='CVEmmaVodable'>
              fr
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
