import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonNext,
} from 'pure-react-carousel';

function SlideContent(props) {
  return (
    <div className='SlideContainer'>
      <div className='SlideDesc'>
        <div className='title'>
          <h2>{props.name}</h2>
          <h2 className='date'>{props.date}</h2>
        </div>
        {props.content.map((el, id) => {
          return <p key={id} dangerouslySetInnerHTML={{ __html: el }}></p>;
        })}
        <p>
          
          {props.authors.map((el, id) => {
            return (
              <span key={id}>
                {id === 0 ? 'Teams: '+ el : el}
                {id + 1 < props.authors.length && ', '}
              </span>
            );
          })}
        </p>
        <p>
          {props.link.map((el, id) => {
            return (
              <span key={id}>
                <a href={el.href} target='_blank' rel='noreferrer'>
                  {el.name}
                </a>
                {id + 1 < props.link.length && ' - '}
              </span>
            );
          })}
        </p>
      </div>
      <div class="Image">
        <img src={require('./assets/'+props.images[Math.floor(Math.random()*props.images.length)].src)}/>
      </div>
      
    </div>
  );
}

export default SlideContent;
