import './App.css';

import React from 'react';
import Projects from './Projects.js';
import About from './About.js';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className='App'>
        <div className='Header'>
          <h1 className= 'Name-title'>Emma Vodable</h1>
          <ul className='Menu'>
            <li>
              <Link to='/projects'>Projects</Link>
            </li>{' '}
            <li>
              <Link to='/about'>About me</Link>
            </li>{' '}
          </ul>
        </div>
        <div className='Container'>
          <Switch>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/projects'>
              <Projects />
            </Route>
            <Route path='/'>
              <Projects />
            </Route>
          </Switch>
        </div>
        <p className='footer'>
          fait avec ❤{' - '}
          <a
            className='App-link'
            target='_blank'
            rel='noreferrer'
            href='https://www.linkedin.com/in/emma-vodable-394b6a150/'
          >
            LinkedIn
          </a>{' - '}
          <a
            className='App-link'
            target='_blank'
            rel='noreferrer'
            href='https://art3mma.itch.io/'
          >
            Itch.io
          </a>{' - '}
          <a
            className='App-link'
            target='_blank'
            rel='noreferrer'
            href='https://github.com/emmavbl'
          >
            Github
          </a>
          
        </p>
      </div>
    </Router>
  );
}

export default App;
