const data = [
  {
    code: true,
    name: 'Ubisoft Bordeaux',
    date: 'depuis avril 2022',
    content: [
      'Pendant 3 trimestres, j\'ai d\'abord rejoins l\'équipe UI d\'<b>un projet multijoueur</b> non annoncé sur Unreal Engine 4. J\'ai réalisé quelques <b>fonctionnalités d\'acccessibilité </b>et de<b> HUD.</b>',
      'J\'ai ensuite rejoins l\'équipe de programmation <b>3C</b> d\'<b>Assassins\'s Creed : Mirage</b>, un jeu hommage à la license populaire qui sortira en 2023.'
    ],
    authors: [],
    link: [],
    images: [
      { name: 'Mirage visual', src: 'mirage.jpg' },
    ],
  },
  {
    code: true,
    name: 'Horribunnies',
    content: [
      'Lors d\'un <b>stage de 3 mois</b> chez <a href="https://abidingbridge.com/">Abiding Bridge</a>, j\'ai participer au développement technique de <a href="https://horribunnies.com/">Horribunnies</a>, un jeu d\'élevage de lapins développé par le collectif <a href="https://twitter.com/bunstackgames">Bunstack</a>. Ce jeu ambitionne de sortir sur pc, console et mobile.',
      'Mon stage portait sur la mise à jour de fonctionnalités et l\'ajouts de nouveaux <b>outils développeurs</b> (éditeur de dialogues).',
      'J\'ai approfondis mes connaissance d\'<b>Unity</b>, spécifiquement en ce qui concerne la programmation d\'<b>interfaces utilisateurs</b> et de <b>devtools</b>.',
    ],
    date: 'été 2021',
    authors: [
      'Abiding Bridge',
      'Bunstack'
    ],
    link: [
      {
        name: "Page Steam",
        href: 'https://store.steampowered.com/app/1187990/Horribunnies/',
      },
      {
        name: "trailer",
        href:"https://cdn.cloudflare.steamstatic.com/steam/apps/256788539/movie480_vp9.webm?t=1591806208"
      }
    ],
  
    images: [
      { name: 'Horribunnies logo and illustration', src: 'horribunnies/horribunnies1.png' },
      { name: 'Horribunnies Hutches', src: 'horribunnies/horribunnies2.png' },
      { name: 'Horribunnies Shop', src: 'horribunnies/horribunnies3.png' },
    ],
  },
  {
    code: true,
    name: 'Lost Papers',
    content: [
      'Ce jeu à été réalisé en 48h lors de la <a href="https://itch.io/jam/gamejam-color-1">GameJam Color</a> de 803Z, bureau des arts de mon université. Le thème était <b>cours de récré</b> et la contrainte était d\'avoir le orange comme couleur principale.',
      'Dans Lost Paper, nous jouons un avion en papier à la recherche des objets perdus dans la cours de récrée.',
      'J\'ai réalisé ce jeu seule sur Unreal Engine 4. Lost Papers a obtenu <b>le prix du meilleur visuel</b> de la Jam.',
    ],
    date: 'février 2022',
    authors: [
      'Emma Vodable'
    ],
    link: [
      {
        name: "tester sur Itch.io",
        href: 'https://art3mma.itch.io/lost-papers',
      }
    ],
    
    images: [
      { name: 'Lost Papers visual', src: 'lostpapers/lostpaper1.png' },
      { name: 'Lost Papers Gameplay', src: 'lostpapers/lostpaper2.png' },
      { name: 'Lost Papers environment', src: 'lostpapers/lostpaper3.png' },
    ],
  },
  {
    code: true,
    name: 'Hide and Seas',
    content: [
      'Hide and Seas est un jeu mélant <b>aventure</b> et <b>infiltration</b>. Volez l\'artefact de toutes les citées de l\'archipel afin de completer votre revanche et regagner votre place dans votre communauté.',
      'Ce jeu ce compose de deux phases, la navigation entre les niveaux, à bateau volant, et l\'infiltration au sein de <b>niveaux généré procéduralement</b>. J\'ai particulièrement travaillé sur cette génération de niveau.',
      'Nous avons réalisé ce projet avec <b>Unreal Engine 4</b>.',
     ],
    date: 'février 2022',
    authors: [
      'Emma Vodable',
      'Thomas Zorroché',
      'Luc Pinguet',
      'Eloise Tassin',
      'Léa Laffitte',
      'Félix Olart'
    ],
    link: [
      {
        name: "Github",
        href: 'https://github.com/Thomas-Zorroche/Projet-3D',
      }
    ],

    images: [
      { name: 'Navigation navale entre les niveaux', src: 'hideandseas/hideandseas1.png' },
      { name: 'Fin de niveau', src: 'hideandseas/hideandseas2.png' },
      { name: 'Etape d\'infiltration', src: 'hideandseas/hideandseas3.png' },
    ],
  },
  {
    code: true,
    name: 'Freezy Kingdom',
    content: [
      'Réalisé lors de la <a href="https://itch.io/jam/chill-and-game-jam">Chill&Game</a> Jam 2021 organisé par le centre Pompidou et le Studio16/13, Freezy Kingdom ets un jeu pitché par un groupe de collégien.ne.s autours du thème "<b>Le pouvoir à la jeunesse</b>". ',
      'Ce jeu met en scène un groupe de jeunes pingouins jouant à diriger l\'iceberg. Inspiré de <a href="https://reignsgame.com/reigns/">Reigns</a>, le joueur repond aux demandes des habitants tout en tentant de rester le plus longtemps au pouvoir.',
      'Crée en parrallèle de mes cours, ce projet m\'a permis d\'apprendre beaucoup sur <b>Unity</b>. J\'ai en effet été la <b>developpeuse principale</b> de ce projet tout en participant à la <b>création graphique des interfaces</b>.',
    ],
    date: 'avril 2021',
    authors: [
      'Les Blettes'
    ],
    link: [
      {
        name: "tester sur Itch.io",
        href: 'https://lesblettes.itch.io/freezy-kingdom',
      },
      {
        name: "demo vidéo",
        href:"https://www.youtube.com/watch?v=_h831M4DzpY"
      }
    ],

    images: [
      { name: 'Freezy Kingdom menu', src: 'frozenkingdom/FK3.png' },
      { name: 'Freezy Kingdom game', src: 'frozenkingdom/FK2.PNG' },
      { name: 'Freezy kingdom end', src: 'frozenkingdom/FK1.PNG' },
    ],
  },
  {
    code: true,
    name: 'Lost In Abyss',
    content: [
      "Lost in Abyss est un jeu vidéo d'Escape Game à la première personne. A bord du Nautilus, en expédition vers la cité perdue de l'Antlantis, le joueur se retrouve enfermé et seul. Il devra résoudre toutes les enigmes de la salle de commande pour pouvoir sortir et remonter à la surface.",
      'Lors de ce projet, je me suis essayé à la modélisation et au texturing d\'assets pour le jeu-vidéo. J\'ai notamment appris <b>Substance Painter</b> et une partie de leur <b>intégration Gameplay dans Unreal Engine 4</b>.',
    ],
    date: 'mars 2021',
    authors: [
      'Emma Vodable',
      'Thomas Zorroché',
      'Luc Pinguet',
      'Léa Rostoker',
    ],
    link: [
      {
        name: "tester sur Itch.io",
        href: 'https://art3mma.itch.io/lost-in-abyss',
      },
      {
        name : "demo vidéo",
        href:"https://www.youtube.com/watch?v=48Ddtk6rE7Q"
      }
    ],

    images: [
      { name: 'lost in abyss beginning', src: 'lostinabyss/lia1.png' },
      { name: 'lost in abyss enigma', src: 'lostinabyss/lia2.png' },
      { name: 'lost in abyss enigma', src: 'lostinabyss/lia3.png' },
      { name: 'lost in abyss enigma', src: 'lostinabyss/lia4.png' },
      { name: 'lost in abyss enigma', src: 'lostinabyss/lia5.png' },
    ],
  },
  {
    code: true,
    name: 'Oniris',
    content: [
      'Codé entièrement en C++ avec la librairie OpenGL, Oniris est un jeu de contemplation et d\'exploration. Il s\'inspire visuellement de jeux tel que <a href="https://www.amongtreesgame.com/">Among Trees</a>. ',
      "Le joueur est naufragé sur une île où se sont déroulé d'étranges expériences et ou la nature mystique à repris ces droits. Au fur et à mesure de l'exploration de l'île, nous découvrons des indices qui narrent l'histoire des lieux.",
      "Réalisé en près d'un mois à deux, j'ai travaillé à la <b>programmation Gameplay</b> et entre autre le système d'inventaire et d'affichage des GUI). Je me suis aussi atelée à la <b>création du HUD et des menus</b>.",
    ],
    date: 'décembre 2020',
    authors: ['Emma Vodable', 'Thomas Zorroché'],
    link: [
      { name: 'tester via github', href: 'https://github.com/Thomas-Zorroche/Oniris' },
      { name: 'demo video', href: 'https://youtu.be/ZbvHfe1QMOk' },
    ],

    images: [
      { name: 'Oniris 01', src: 'oniris/Oniris01.png' },
      { name: 'map oniris', src: 'oniris/mapmenu.png' },
      { name: 'Oniris 02', src: 'oniris/Oniris02.png' },
      { name: 'correspondance oniris', src: 'oniris/correspondance.png' },
      { name: 'Oniris 04', src: 'oniris/Oniris04.png' },
      { name: 'mot oniris', src: 'oniris/mot.png' },
    ],
  },
  {
    code: true,
    name: 'Guardians of Knaerwood',
    content: [
      "Guardians of Knaerwood est un jeu de stratégie action devellopé en <b>C++</b> à l'aide de la librairie SDL et OpenGL. L'histoire se déroule dans le mythique Royaume de Knaerwoods, royaume déchu d'Alexander Soulimac.",
      "J'ai réalisé les <b>assets graphiques</b> en pixel art des menus et du jeu ainsi que des <b>fonctionnalités des menus et du gameplay</b>. ",
    ],
    date: 'avril 2020',
    authors: ['Emma Vodable', 'Thomas Zorroché'],
    link: [
      {
        name: 'GitLab',
        href: 'https://gitlab.com/emmavbl/guardians-of-knaerwood',
      },
    ],

    images: [
      { name: 'knaerwood', src: 'knaerwood/01.png' },
      { name: 'knaerwood', src: 'knaerwood/02.png' },
      { name: 'knaerwood', src: 'knaerwood/03.png' },
    ],
  },
  {
    code: false,
    name: 'Projet 89',
    content: [
      'En collaboration avec la <a href="https://www.compagniesanslanommer.com/">Compagnie sans la nommer</a>, nous avons créer cette application permettant la mise en valeur de leur collecte de souvenir sur l\'année 1989.',
      "Ce projet permet au visiteur de <b>se plonger dans les parcours de 1989</b> tel que définis par la compagnie dans leur recherches théatrales. C'est une application fullstack utilisant React.js et Express.js.",
      'Je me suis occupée de <b>la gestion de projet</b> et du <b>developpement back-end.</b>',
    ],
    date: 'janvier 2021',
    authors: [
      'Emma Vodable',
      'Erwann Lefevre',
      'Natacha Liao',
      'Esther Gartner',
    ],
    link: [
      {
        name: "WIP de l'app",
        href: 'https://ciesanslanommer.github.io/Projet89/',
      },
      {
        name: 'github',
        href: 'https://github.com/ciesanslanommer/Projet89',
      },
    ],

    images: [
      { name: 'survol projet 89', src: 'projet89/survol.gif' },
      { name: 'parcours projet 89', src: 'projet89/parcours.gif' },
      { name: 'backend projet 89', src: 'projet89/backend.gif' },
    ],
  },
  {
    code: false,
    name: 'Ça pique',
    content: [
      'Ce petit court métrage d\'animation est inspirée de l\'univers de la série <b>"The Amazing World of Gumball"</b>. "Ca pique" raconte la paranoïa d\'un ballon en visite médicale.',
      "J'ai réalisé <b>la modélisation</b> de la banane et <b>l'animation</b> des plans ci-contre. Le court a été entièrement réalisé sur <b>Blender</b>.",
    ],
    date: 'novembre 2020',
    authors: [
      'Emma Vodable',
      'Thomas Zorroché',
      'Eloïse Tassin',
      'Alaric Rougnon-Glasson',
      'Luc Pinguet',
    ],
    link: [
      {
        name: 'youtube',
        href: 'https://www.youtube.com/watch?v=6dnbcO3m0gE',
      },
    ],

    images: [
      { name: 'Ca pique', src: 'capique/0019.png' },
      { name: 'Ca pique', src: 'capique/0026.png' },
      { name: 'Ca pique', src: 'capique/0145.png' },
      { name: 'Ca pique', src: 'capique/0079.png' },
    ],
  },
  {
    code: false,
    name: 'Ako',
    content: [
      "Ako est un court métrage thriller d'anticipation. Morgan s'implante une technologie capable de resoudre ses déficiences humaines. ",
      'Le plus grand défis de ce projet a été de le réalisé en période crise sanitaire avec toutes les rencontres et déplacement que cela implique. ',
      "J'ai occupé le rôle de <b>directrice de casting</b>, <b>mixeuse son</b> et j'ai designé l'UI de l'implant.",
    ],
    date: 'octobre 2020',
    authors: [
      'Emma Vodable',
      'Thomas Zorroché',
      'Felix Olart',
      'Eloïse Tassin',
    ],
    link: [
      { name: 'Youtube', href: 'https://www.youtube.com/watch?v=OIJATSn_oUA' },
    ],

    images: [
      { name: 'Ako', src: 'ako/ako (1).png' },
      { name: 'Ako', src: 'ako/ako (2).png' },
      { name: 'Ako', src: 'ako/ako (3).png' },
      { name: 'Ako', src: 'ako/ako (4).png' },
      { name: 'Ako', src: 'ako/ako (5).png' },
      { name: 'Ako', src: 'ako/ako (6).png' },
    ],
  },
  {
    code: false,
    name: 'Bre-son',
    content: [
      "Petite BD réalisé en solo lors des <a href=\"https://25hbd.com/\">25h de la bd</a>. Rassemblé.e.s avec l'<a href='http://www.803z.fr/'>associations des arts</a> de mon université, j'ai réalisé cette bande dessinée en direct sur twitch lors d'un live marathon de 25h (ce qui explique les incohérences et l'absurde).",
      'Bre-son signifie Sombre, Glauque en ver-lan. Cette BD est un <b>petit polar</b> absurde en 12 planches.',
      "J'ai réalisé les dessins à l'<b>aquarelle</b> et au feutre.",
    ],
    date: 'octobre 2020',
    authors: ['Emma Vodable'],
    link: [
      {
        name: 'Participation',
        href: 'https://25hbd.com/participants/2020/art3mma/',
      },
      {
        name: 'BD complète',
        href:
          'https://drive.google.com/drive/folders/1JQ-HJ6gJJmh6q8OWKP8G-62l6Up0OB-K',
      },
    ],

    images: [
      { name: 'Bresson', src: 'bresson/1.png' },
      { name: 'Bresson', src: 'bresson/3.png' },
      { name: 'Bresson', src: 'bresson/6.png' },
      { name: 'Bresson', src: 'bresson/10.png' },
    ],
  },
  {
    code: false,
    name: 'Movimac',
    content: [
      "Ce site web est un projet front et back-end, réalisé en HTML/CSS, PHP et MySQL. Cette platforme permet de creer des listes de films pour <b>organiser des marathons cinés</b>. On peut considérer Movimac comme un <b>réseaux social</b> ou il est possible d'ajouter un coeur sur les listes de ses amis.",
      "J'ai réalisé la <b>charte graphique</b> du site ainsi qu'une partie du <b>developpement</b>.",
    ],
    date: 'mai 2020',
    authors: [
      'Emma Vodable',
      'Thomas Zorroché',
      'Eloïse Tassin',
      'Félix Olart',
      'Judicaelle Six',
    ],
    link: [
      {
        name: 'Projet',
        href: 'https://thomaszorroche.com/movimac',
      },
    ],

    images: [
      { name: 'movimac', src: 'movimac/01.png' },
      { name: 'movimac', src: 'movimac/02.png' },
      { name: 'movimac', src: 'movimac/03.png' },
    ],
  },
 
  {
    code: false,
    name: 'Mistero e Palla di Gomma',
    content: [
      "Réalisé lors des 48h du court-métrage de <a href='http://www.803z.fr/'>803z</a>, Mistero e Palla di Gomma (mystère et boule de gomme en Italien) est un film satirique de polar.",
      'Nous avons récu le prix de la mise en scène et le prix du public à la fin des 48h.',
      "J'ai été <b>ingénieure sonore et comédienne</b> sur le tournage. J'ai aussi participé à l'<b>écriture du scénario</b>.",
    ],
    date: 'novembre 2019',
    authors: [
      'Ludwig Chieng',
      'Théo Gautier',
      'Emma Vodable',
      'Fabian Adam',
      'Alexandre Escudero',
      'Sterenn Fonseca',
      'Simon Ferragut',
      'Théo Dauphin',
      'Nelly Dupuydenus',
      'Achraf Lemghari',
    ],
    link: [
      {
        name: 'Youtube',
        href: 'https://www.youtube.com/watch?v=LVKekCpUvoU&t',
      },
    ],

    images: [
      { name: 'Palla di Gomma', src: 'palladigomma/palladigomma (1).gif' },
      { name: 'Palla di Gomma', src: 'palladigomma/palladigomma (2).gif' },
      { name: 'Palla di Gomma', src: 'palladigomma/palladigomma (3).gif' },
      { name: 'Palla di Gomma', src: 'palladigomma/palladigomma (4).gif' },
    ],
  },
  {
    code: false,
    name: 'Deadly Match',
    content: [
      'Deadly Match est un court métrage d\'anticipation humoristique réalisé pendant mes années de DUT. Il fait partie de la série de courts <b>"Horizons 2141"</b> racontant Grenoble dans 123 ans. ',
      "Nous avons, pour ce film, gagné le prix des équipes et le prix du scénario au festival Vidé'alpes.",
    ],
    date: 'janvier 2019',
    authors: [
      'Emma Vodable',
      'Nathan Rabadan',
      'Louis Raillard',
      'Fanny Trinh',
    ],
    link: [
      { name: 'Youtube', href: 'https://www.youtube.com/watch?v=MNiP6_qoGU8' },
    ],

    images: [
      { name: 'Deadly match', src: 'deadlymatch/01.png' },
      { name: 'Deadly match', src: 'deadlymatch/02.png' },
      { name: 'Deadly match', src: 'deadlymatch/03.png' },
      { name: 'Deadly match', src: 'deadlymatch/04.png' },
      { name: 'Deadly match', src: 'deadlymatch/05.png' },
    ],
  },
  // {
  //   name: 'Guardians of Knaerwood',
  //   content: [
  //     'Codé entièrement en C++ avec la librairie OpenGL, Oniris est un jeu de contemplation et d\'exploration. Il s\'inspire visuellement de jeux tel que <a href="https://www.amongtreesgame.com/">Among Trees</a>. ',
  //     "Le joueur est naufragé sur une île où se sont déroulé d'étranges expériences et ou la nature mystique à repris ces droits. Au fur et à mesure de l'exploration de l'île, nous découvrons des indices qui narrent l'histoire des lieux.",
  //     "Réalisé en près d'un mois à deux, j'ai travaillé à la <b>programmation Gameplay</b> et à la <b>création des assets graphiques</b> (UI).",
  //   ],
  //   authors: ['Emma Vodable', 'Thomas Zorroché'],
  //   link: [
  //     { name: 'github', href: 'https://github.com/Thomas-Zorroche/Oniris' },
  //   ],

  //   images: [
  //     { name: 'map oniris', src: 'oniris_map.png' },
  //     { name: 'map sun', src: 'oniris_sun.png' },
  //     { name: 'map house', src: 'oniris_house.png' },
  //     { name: 'map ui', src: 'oniris_ui.png' },
  //   ],
  // },
];

export default data;
